import chainConfig from '../config/chains.json';

const ChainList = ({ chains, onChainSelect }) => {
  const activeChains = chains.filter(
    chain => chain.active && chain.name.toLowerCase() !== 'lambda' && chain.name.toLowerCase() !== 'vidulum'
  );

  const formatChainName = (name) => {
    return chainConfig.chainNames[name.toLowerCase()] || name.charAt(0).toUpperCase() + name.slice(1);
  };

  const getExplorerUrl = (chain) => {
    const { baseUrl, specialPaths } = chainConfig.explorerConfig;
    const chainName = chain.name.toLowerCase();
    const pathName = specialPaths[chainName] || chainName;
    return `${baseUrl}/${pathName}/staking/${chain.operator_address}`;
  };

  return (
    <section id="chains" className="my-20">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8 dark:text-white">Chains</h2>
        
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6">
          {activeChains.map((chain) => (
            <div
              key={chain.name}
              className="validator-card transform hover:scale-105 transition-transform duration-200"
            >
              <img
                src={`/logos/${chain.name}.png`}
                alt={`${formatChainName(chain.name)} Logo`}
                className="w-24 h-24 mx-auto mb-4 object-contain"
              />
              
              <h5 className="text-lg font-semibold mb-4 dark:text-white">
                {formatChainName(chain.name)}
              </h5>

              <div className="space-y-2">
                <a
                  href={getExplorerUrl(chain)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-primary block w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
                >
                  Explorer
                </a>

                <a
                  href={`https://restake.app/${chain.name}/${chain.operator_address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-secondary block w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
                >
                  🔥 Restake 🔥
                </a>

                <button
                  onClick={() => onChainSelect(chain)}
                  className="btn-info block w-full bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
                >
                  More Info
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ChainList;
