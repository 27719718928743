import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import chainConfig from '../config/chains.json';

const MoreInfoModal = ({ isOpen, onClose, chain }) => {
  if (!chain) return null;

  const formatChainName = (name) => {
    return chainConfig.chainNames[name.toLowerCase()] || name.charAt(0).toUpperCase() + name.slice(1);
  };

  const formatNumber = (num) => {
    if (!num) return 'N/A';
    return num.toLocaleString();
  };

  const formatPercentage = (value) => {
    if (!value) return 'N/A';
    return `${(parseFloat(value) * 100).toFixed(2)}%`;
  };

  const renderPublicNodes = () => {
    if (!chain.public_nodes || (!chain.public_nodes.rpc && !chain.public_nodes.rest && !chain.public_nodes['evm-http-jsonrpc'])) {
      return 'N/A';
    }

    return (
      <div className="space-y-2">
        {chain.public_nodes.rpc && (
          <div>
            RPC: <a href={chain.public_nodes.rpc[0].address} className="text-blue-500 hover:text-blue-600 dark:text-blue-400" target="_blank" rel="noopener noreferrer">RPC Link</a>
          </div>
        )}
        {chain.public_nodes.rest && (
          <div>
            REST: <a href={chain.public_nodes.rest[0].address} className="text-blue-500 hover:text-blue-600 dark:text-blue-400" target="_blank" rel="noopener noreferrer">REST Link</a>
          </div>
        )}
        {chain.public_nodes['evm-http-jsonrpc'] && (
          <div>
            EVM: <a href={chain.public_nodes['evm-http-jsonrpc'][0].address} className="text-blue-500 hover:text-blue-600 dark:text-blue-400" target="_blank" rel="noopener noreferrer">EVM Link</a>
          </div>
        )}
      </div>
    );
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 dark:text-white flex justify-between items-center"
                >
                  <span>{formatChainName(chain.name)} Details</span>
                  <button
                    onClick={onClose}
                    className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                  >
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </Dialog.Title>

                <div className="mt-4">
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                      <tr>
                        <th className="py-2 text-left text-sm font-medium text-gray-500 dark:text-gray-400">Status</th>
                        <td className="py-2 text-sm text-gray-900 dark:text-white">
                          {chain.active ? 'Active' : 'Inactive'}
                        </td>
                      </tr>
                      <tr>
                        <th className="py-2 text-left text-sm font-medium text-gray-500 dark:text-gray-400">Current Rate</th>
                        <td className="py-2 text-sm text-gray-900 dark:text-white">
                          {formatPercentage(chain.commission?.commission_rates?.rate)}
                        </td>
                      </tr>
                      <tr>
                        <th className="py-2 text-left text-sm font-medium text-gray-500 dark:text-gray-400">Max Rate</th>
                        <td className="py-2 text-sm text-gray-900 dark:text-white">
                          {formatPercentage(chain.commission?.commission_rates?.max_rate)}
                        </td>
                      </tr>
                      <tr>
                        <th className="py-2 text-left text-sm font-medium text-gray-500 dark:text-gray-400">Max Change Rate</th>
                        <td className="py-2 text-sm text-gray-900 dark:text-white">
                          {formatPercentage(chain.commission?.commission_rates?.max_change_rate)}
                        </td>
                      </tr>
                      <tr>
                        <th className="py-2 text-left text-sm font-medium text-gray-500 dark:text-gray-400">Total Tokens Delegated</th>
                        <td className="py-2 text-sm text-gray-900 dark:text-white">
                          {formatNumber(chain.delegations?.total_tokens_display)}
                        </td>
                      </tr>
                      <tr>
                        <th className="py-2 text-left text-sm font-medium text-gray-500 dark:text-gray-400">Total Delegators</th>
                        <td className="py-2 text-sm text-gray-900 dark:text-white">
                          {formatNumber(chain.delegations?.total_count)}
                        </td>
                      </tr>
                      <tr>
                        <th className="py-2 text-left text-sm font-medium text-gray-500 dark:text-gray-400">Total Delegated Value</th>
                        <td className="py-2 text-sm text-gray-900 dark:text-white">
                          ${formatNumber(chain.delegations?.total_usd)}
                        </td>
                      </tr>
                      <tr>
                        <th className="py-2 text-left text-sm font-medium text-gray-500 dark:text-gray-400">Public Nodes</th>
                        <td className="py-2 text-sm text-gray-900 dark:text-white">
                          {renderPublicNodes()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default MoreInfoModal;
