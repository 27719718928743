const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-800 text-gray-400 py-6 mt-20">
      <div className="container mx-auto px-4">
        <div className="text-center">
          <p className="text-sm">
            &copy; {currentYear} 🚀 WHEN MOON 🌕 WHEN LAMBO 🔥
          </p>
          <div className="mt-2 text-xs">
            <p>
              Elite Validator: Explorer, HA, Horcrux, Auto-Stake, Reliable Relayer,
              Cosmovisor, Hourly Snapshots, Docker/Bare Metal, Discord Support
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
