const Overview = ({ data }) => {
  const activeChains = data.validator.chains.filter(
    chain => chain.active && chain.name.toLowerCase() !== 'lambda' && chain.name.toLowerCase() !== 'vidulum'
  );

  const totalUsd = data.validator.total_usd * 14;
  const totalUsers = data.validator.total_users;
  const totalChains = activeChains.length;

  const stats = [
    {
      label: 'Total Assets',
      value: `$${totalUsd.toFixed(2)}+`,
      icon: '💰'
    },
    {
      label: 'Total Delegators',
      value: `${totalUsers}+`,
      icon: '👥'
    },
    {
      label: 'Total Chains',
      value: `${totalChains}+`,
      icon: '⛓️'
    }
  ];

  return (
    <section id="overview" className="my-20">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8 dark:text-white">Overview</h2>
        
        <div className="mb-8">
          <p className="text-lg text-gray-700 dark:text-gray-300 mb-4">
            Welcome to the <strong>🚀 WHEN MOON 🌕 WHEN LAMBO 🔥</strong> validator, where we prioritize your
            investments and ensure maximum rewards. Our services are designed to provide a seamless and secure
            staking experience.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-6 mb-8">
          {stats.map((stat, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 text-center transform hover:scale-105 transition-transform duration-200"
            >
              <div className="text-4xl mb-2">{stat.icon}</div>
              <h3 className="text-xl font-semibold mb-2 dark:text-white">{stat.label}</h3>
              <p className="text-2xl font-bold text-primary dark:text-blue-400">{stat.value}</p>
            </div>
          ))}
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <h3 className="text-xl font-semibold mb-4 dark:text-white">Key Features</h3>
          <ul className="space-y-3 text-gray-700 dark:text-gray-300">
            <li className="flex items-center">
              <span className="mr-2">🔍</span>
              <span><strong>Explorer Integration:</strong> Stay updated with real-time data and performance metrics.</span>
            </li>
            <li className="flex items-center">
              <span className="mr-2">🔄</span>
              <span><strong>High Availability (HA):</strong> Our infrastructure ensures uninterrupted staking operations.</span>
            </li>
            <li className="flex items-center">
              <span className="mr-2">🔐</span>
              <span><strong>Horcrux Support:</strong> Enhanced security with distributed key management.</span>
            </li>
            <li className="flex items-center">
              <span className="mr-2">📈</span>
              <span><strong>Auto-Stake:</strong> Automatic reinvestment of rewards for maximum returns.</span>
            </li>
            <li className="flex items-center">
              <span className="mr-2">🌐</span>
              <span><strong>Reliable Relayer:</strong> Smooth transaction processing and network communication.</span>
            </li>
            <li className="flex items-center">
              <span className="mr-2">🤖</span>
              <span><strong>Cosmovisor:</strong> Automated upgrades and management system.</span>
            </li>
            <li className="flex items-center">
              <span className="mr-2">💾</span>
              <span><strong>Hourly Snapshots:</strong> Regular data backups for enhanced security.</span>
            </li>
            <li className="flex items-center">
              <span className="mr-2">🐳</span>
              <span><strong>Flexible Deployment:</strong> Support for both Docker and Bare Metal setups.</span>
            </li>
            <li className="flex items-center">
              <span className="mr-2">💬</span>
              <span><strong>Discord Support:</strong> Active community and instant support channel.</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Overview;
