const Contact = () => {
  const socialLinks = [
    {
      name: 'Discord',
      icon: '/icons/discord.png',
      href: 'https://discord.gg/mhF5aAbTck'
    },
    {
      name: 'GitHub',
      icon: '/icons/github.png',
      href: 'https://github.com/When-Moon-When-Lambo'
    },
    {
      name: 'TikTok',
      icon: '/icons/tiktok.png',
      href: 'https://www.tiktok.com/@when_moon_when_lambo'
    }
  ];

  return (
    <section id="contact" className="my-20">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8 dark:text-white">Contact</h2>
        
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <span className="text-gray-600 dark:text-gray-400">Email:</span>
              <a 
                href="mailto:woelig359@gmail.com"
                className="text-blue-600 dark:text-blue-400 hover:underline"
              >
                woelig359@gmail.com
              </a>
            </div>

            <div className="flex items-center space-x-2">
              <span className="text-gray-600 dark:text-gray-400">Links:</span>
              <a 
                href="https://links.whenmoonwhenlambo.money/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 dark:text-blue-400 hover:underline"
              >
                Website Links
              </a>
            </div>

            <div className="mt-6">
              <h3 className="text-xl font-semibold mb-4 dark:text-white">Social Media</h3>
              <div className="grid grid-cols-3 md:grid-cols-6 gap-4">
                {socialLinks.map((link) => (
                  <a
                    key={link.name}
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex flex-col items-center group"
                  >
                    <div className="w-12 h-12 rounded-full overflow-hidden bg-white dark:bg-white p-2 transition-transform group-hover:scale-110 shadow-md">
                      <img
                        src={link.icon}
                        alt={`${link.name} icon`}
                        className="w-full h-full object-contain"
                      />
                    </div>
                    <span className="mt-2 text-sm text-gray-600 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-400">
                      {link.name}
                    </span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
