import { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import FullscreenImage from './components/FullscreenImage';
import Overview from './components/Overview';
import ChainList from './components/ChainList';
import Contact from './components/Contact';
import Footer from './components/Footer';
import LoadingScreen from './components/LoadingScreen';
import MoreInfoModal from './components/MoreInfoModal';

function App() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [selectedChain, setSelectedChain] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchData();
    document.body.classList.add('content-visible');
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('/data.json');
      const jsonData = await response.json();
      setData(jsonData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    document.documentElement.classList.toggle('dark');
  };

  const handleChainSelect = (chain) => {
    setSelectedChain(chain);
    setShowModal(true);
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className={`min-h-screen ${isDarkMode ? 'dark' : ''}`}>
      <Navbar isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
      
      <FullscreenImage />

      <main className="container mx-auto px-4 py-8">
        {data && (
          <>
            <Overview data={data} />
            <ChainList 
              chains={data.validator.chains} 
              onChainSelect={handleChainSelect}
            />
            <Contact />
          </>
        )}
      </main>

      <Footer />

      <MoreInfoModal 
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        chain={selectedChain}
      />
    </div>
  );
}

export default App;
