const FullscreenImage = () => {
  return (
    <>
      {/* Desktop version */}
      <div className="fullscreen-image">
        <div className="text-center bg-black bg-opacity-50 p-8 rounded-lg backdrop-blur-sm">
          <h1 className="text-4xl font-bold mb-4 text-white">
            🚀 WHEN MOON 🌕 WHEN LAMBO 🔥
          </h1>
          <p className="text-xl text-white">
            Elite Validator: Secure, Reliable, and Rewarding
          </p>
        </div>
      </div>

      {/* Mobile version */}
      <div className="md:hidden h-[300px] bg-[url('/background.png')] bg-no-repeat bg-center bg-cover flex items-center justify-center">
        <div className="text-center bg-black bg-opacity-50 p-6 w-full backdrop-blur-sm">
          <h1 className="text-2xl font-bold mb-3 text-white">
            🚀 WHEN MOON 🌕 WHEN LAMBO 🔥
          </h1>
          <p className="text-lg text-white">
            Elite Validator: Secure, Reliable, and Rewarding
          </p>
        </div>
      </div>
    </>
  );
};

export default FullscreenImage;
